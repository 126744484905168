/** @jsx jsx */
import { css, jsx } from "@emotion/react";

type DataProps = {
  color: string;
  onClick?: () => void;
};

const CloseBtn = ({ color, onClick = () => {} }: DataProps): JSX.Element => {
  return (
    <button
      css={css`
        position: relative;
        width: 1.5rem;
        height: 1.5rem;
        background: transparent;
        border: none;
        /* margin: 0 6px; */
        margin: 0;
        &::before,
        &::after {
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          width: 2px; /* 棒の幅（太さ） */
          height: 1.5rem; /* 棒の高さ */
          background: ${color};
        }
        &::before {
          transform: translate(-50%, -50%) rotate(45deg);
        }
        &::after {
          transform: translate(-50%, -50%) rotate(-45deg);
        }
        &:focus {
          outline: none;
        }
      `}
      onClick={onClick}
    ></button>
  );
};

export default CloseBtn;
